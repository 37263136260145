<template>
    <v-chip-group multiple :small="small">
        <v-chip
            v-for="(tag, index) in tags"
            :filter="canSelect"
            outlined
            :small="small"
            :key="index">
            {{ tag }}
        </v-chip>
    </v-chip-group>
</template>

<script>
export default {
    name: 'BT-Tag-Chips',
    props: {
        canSelect: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: true
        },
        value: null
    },
    computed: {
        tags() {
            return this.value != null ? this.value.split(',') : [];
        }
    }
}
</script>